import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// auth
import { AuthGuard } from 'src/auth/guard';
// layouts
import DashboardLayout from 'src/layouts/dashboard';
// components
import { LoadingScreen } from 'src/components/loading-screen';
import { hasPermission } from 'src/utils/permissions';


// ----------------------------------------------------------------------

// OVERVIEW
// const OverviewListPage = lazy(() => import('src/pages/dashboard/overview/overview-list'));
const IndexPage = lazy(() => import('src/pages/coming-soon'));

// Tasks
const TasksPage = lazy(() => import('src/pages/dashboard/tasks/task-list'));
const ManageTaskPage = lazy(() => import('src/pages/dashboard/tasks/manage-task'));
const CreateTaskPage = lazy(() => import('src/pages/dashboard/tasks/task_create'));

// Purchase Order
const PurchaseOrderListPage = lazy(() =>
  import('src/pages/dashboard/purchaseOrder/purchase-order-list')
);
const NewPurchaseOrderPage = lazy(() =>
  import('src/pages/dashboard/purchaseOrder/purchase-order-new')
);
const EditPurchaseOrder = lazy(() =>
  import('src/pages/dashboard/purchaseOrder/purchase-order-edit')
);
const ConfirmPurchaseOrderPage = lazy(() =>
  import('src/pages/dashboard/purchaseOrder/purchase-order-confirm')
);


// Drawings
const DrawingListPage = lazy(() => import('src/pages/dashboard/drawing/drawing-list'));
const DrawingUploadPage = lazy(() => import('src/pages/dashboard/drawing/drawing-upload-new'));
const DrawingEditPage = lazy(() => import('src/pages/dashboard/drawing/drawing-upload-edit'));

// Assignments
const AssignmentsListPage = lazy(() => import('src/pages/dashboard/assignments/assignments-list'));


// Documents
const DocumentListPage = lazy(() => import('src/pages/dashboard/document/document-list'));
const DocumentUploadPage = lazy(() => import('src/pages/dashboard/document/document-upload-new'));
const DocumentEditPage = lazy(() => import('src/pages/dashboard/document/document-upload-edit'));

// Client Payments
const ClientPaymentListPage = lazy(() =>
  import('src/pages/dashboard/client-payment/client-payment-list')
);

// ----------------------------------------------------------------------

export function dashboardRoutes(user) {
  return [
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout>
            <Suspense fallback={<LoadingScreen />}>
              <Outlet />
            </Suspense>
          </DashboardLayout>
        </AuthGuard>
      ),
      children: [
        {
          element: <IndexPage />,
          index: true,
        },
        hasPermission(user, 'Tasks', 'Read') && {
          path: 'tasks',
          children: [
            { element: <TasksPage />, index: true },
            hasPermission(user, 'Tasks', 'Update') && {
              path: 'manage',
              element: <ManageTaskPage />,
            },
            hasPermission(user, 'Tasks', 'Create') && {
              path: 'create',
              element: <CreateTaskPage />,
            },
          ].filter(Boolean),
        },
        hasPermission(user, 'Shops Purchase Orders', 'Read') && {
          path: 'purchaseorder',
          children: [
            { element: <PurchaseOrderListPage />, index: true },
            hasPermission(user, 'Shops Purchase Orders', 'Create') && {
              path: 'create',
              element: <NewPurchaseOrderPage />,
            },
            { path: 'edit', element: <EditPurchaseOrder /> },
            hasPermission(user, 'Shops Purchase Orders', 'Update') && {
              path: 'confirm',
              element: <ConfirmPurchaseOrderPage />,
            },
          ].filter(Boolean),
        },
        hasPermission(user, 'Drawings', 'Read') && {
          path: 'drawings',
          children: [
            { element: <DrawingListPage />, index: true },
            hasPermission(user, 'Drawings', 'Create') && {
              path: 'create',
              element: <DrawingUploadPage />,
            },
            { path: 'edit', element: <DrawingEditPage /> },
          ].filter(Boolean),
        },
        hasPermission(user, 'Documents', 'Read') && {
          path: 'documents',
          children: [
            { element: <DocumentListPage />, index: true },
            hasPermission(user, 'Documents', 'Create') && {
              path: 'create',
              element: <DocumentUploadPage />,
            },
            { path: 'edit', element: <DocumentEditPage /> },
          ].filter(Boolean),
        },
        hasPermission(user, 'Laborers Assignments', 'Read') && {
          path: 'assignments',
          children: [{ element: <AssignmentsListPage />, index: true }],
        },
        hasPermission(user, 'Client Milestone', 'Read') && {
          path: 'clientpayments',
          children: [{ element: <ClientPaymentListPage />, index: true }].filter(Boolean),
        },
      ],
    },
  ];
}
